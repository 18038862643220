import { useEffect } from 'react';

import { graphql, navigate , useStaticQuery } from 'gatsby';
// eslint-disable-next-line import/named
import { WithPrismicPreviewResolverProps } from 'gatsby-plugin-prismic-previews';

/**
 * useIsUnpublishedPreview
 *
 * Determines if the preview has been published
 */
export const useIsUnpublishedPreview = ({ isPrismicPreview, prismicPreviewPath }: WithPrismicPreviewResolverProps) => {
  // Let's use a static query to retrieve all known paths. We'll use it later
  // to navigate to the unpublishedPreview page if the document is not
  // published.
  const { allSitePage } = useStaticQuery(graphql`
    {
      allSitePage {
        nodes {
          path
        }
      }
    }
  `);
  const allPaths = allSitePage.nodes.map((node: { path: string }) => node.path);

  // If `isPrismicPreview` is explicitly false, navigate to 404
  useEffect(() => {
    if (isPrismicPreview === false) {
      navigate(`/404`);
    }
  }, [isPrismicPreview]);

  const fullPreviewPath = `${prismicPreviewPath}/`;
  return !allPaths.includes(fullPreviewPath);
};
