import { useEffect } from 'react';

import { navigate } from "gatsby"
// eslint-disable-next-line import/named
import { WithPrismicPreviewResolverProps } from 'gatsby-plugin-prismic-previews';

interface useRedirectPreviewProps {
  isUnpublishedPreview: boolean;
}

/**
 * useRedirectPreview
 *
 * Based on isUnpublishedPreview, determine how to redirect.
 *
 * For published previews, use gatsby navigate to hard redirect to corresponding pages.
 * For unpublished previews, use HTML5 window location to perform a URL change without redirecting
 */
export const useRedirectPreview = ({
  isPrismicPreview,
  prismicPreviewPath,
  isUnpublishedPreview,
}: WithPrismicPreviewResolverProps & useRedirectPreviewProps) => {
  useEffect(() => {
    if (isPrismicPreview && prismicPreviewPath) {
      if (!isUnpublishedPreview) {
        // Navigate to the document's page if page already published & path exists.
        navigate(prismicPreviewPath);
      } else {
        // Navigate to show unpublished preview page
        // Newer Browser with HTML5. Use HTML5 history.replaceState(), to replace the URL in the address bar, but WITHOUT actual redirecting
        window?.history?.replaceState(undefined, 'Loading Prismic Preview', prismicPreviewPath);
      }
    } else if (isPrismicPreview === false) {
      navigate(`/404`);
    } else if (isPrismicPreview === null) {
      // Still loading
    }
  }, [prismicPreviewPath]);
};
