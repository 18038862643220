import * as React from 'react';

import type { PageProps } from 'gatsby';
import { componentResolverFromMap, withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews';

import { linkResolver } from '../../prismic/utils/linkResolver';
import DefaultLayout from './DefaultLayout';
import GenericTemplate from './GenericTemplate';



/**
 * Draft is a placeholder template
 *
 * It only renders, when withPrismicUnpublishedPreview() is loading
 */
const Draft = () => <></>;

// The following `withPrismicUnpublishedPreview` works only with root routes, e.g. `/some-page`
// However given Lendi Platform's Router limitation on root catchall, JAM won't receive such traffic
// The work around is to utilize `/Preview` page, and hard code the `location.pathname` value via props,
// Since `withPrismicUnpublishedPreview` uses `location.pathname`, with the above props, it will be fetch unpublished previews.
const PRISMIC_REPOSITORY_NAME = 'lendiau';
export default withPrismicUnpublishedPreview<never, PageProps<never>>(Draft, [
  {
    repositoryName: PRISMIC_REPOSITORY_NAME,
    linkResolver,
    componentResolver: componentResolverFromMap({
      layout: DefaultLayout,
      generic_template: GenericTemplate,
    }),
  },
]);
