import * as React from 'react';

import type { PageProps, PageRendererProps } from 'gatsby';
// eslint-disable-next-line import/named
import { WithPrismicPreviewResolverProps, withPrismicPreviewResolver } from 'gatsby-plugin-prismic-previews';

import { linkResolver } from '../../prismic/utils/linkResolver';
import { useIsUnpublishedPreview } from '../hooks/useIsUnpublishedPreview';
import { useRedirectPreview } from '../hooks/useRedirectPreview';
import Draft from '../templates/Draft';

/**
 * Preview is a resolver component, which tries if the page is a preview, if so, is it published.
 *
 * If published, useRedirectPreview() will redirect to corresponding page
 * If unpublished, render the Draft template
 *
 * @see https://prismic.io/docs/core-concepts/preview-setup
 */
const Preview = (props: WithPrismicPreviewResolverProps & PageRendererProps & PageProps<never>) => {
  const isUnpublishedPreview = useIsUnpublishedPreview(props);
  useRedirectPreview({ ...props, isUnpublishedPreview });

  const { prismicPreviewPath } = props;
  if (isUnpublishedPreview && prismicPreviewPath) {
    return (
      // Given Lendi Platform's Router limitation, for unpublished preview, we can't redirect
      // Instead, we'll render the following <Draft /> component to display Unpublished Preview
      // In order for <Draft /> and usePrismicUnpublishedPreview to work, we have to override props.location.pathname
      <Draft
        {...props}
        path={prismicPreviewPath}
        uri={prismicPreviewPath}
        location={{
          ...props.location,
          pathname: prismicPreviewPath,
        }}
      />
    );
  } else {
    return <></>;
  }
};

const PRISMIC_REPOSITORY_NAME = 'lendiau';
export default withPrismicPreviewResolver(
  Preview,
  [
    {
      repositoryName: PRISMIC_REPOSITORY_NAME,
      linkResolver,
    },
  ],
  {
    autoRedirect: false, // Given Lendi Platform's Router limitation, JAM won't control 404 page. Disabling autoRedirect.
  }
);
